import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { format, parseISO } from "date-fns";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default function BlogPost({ data }) {
  const {
    title,
    seo_description,
    seo_keywords,
    date_posted,
    body: { html }
  } = data.prismicBlogPost.data;
  useEffect(() => {
    const iframes = document.querySelectorAll(".blog iframe");

    iframes.forEach(iframe => {
      const aspectRatio = iframe.clientHeight / iframe.clientWidth;

      iframe.removeAttribute("height");
      iframe.removeAttribute("width");

      iframe.width = "100%";
      iframe.height = iframe.offsetWidth * aspectRatio;
    });
  });

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_description}
        keywords={seo_keywords?.split(",").map(keyword => keyword.trim())}
      />
      <article className="max-w-3xl w-full mx-auto py-8 px-4 font-proxima-nova blog tracking-wider">
        <h1>{title}</h1>
        {date_posted && (
          <div className="text-center">
            Posted on {format(parseISO(date_posted), "MMMM d, y")}
          </div>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: html
          }}
        ></div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query GetBlogPost($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      data {
        seo_description
        seo_keywords
        title
        body {
          html
        }
        date_posted
      }
    }
  }
`;
